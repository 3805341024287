import React from 'react';
import moment from 'moment';
import { decode } from 'html-entities';
import useDarkMode from '../../hooks/useDarkMode';
import Icon from '../../components/icon/Icon';
import Badge from '../../components/bootstrap/Badge';
import Popovers from '../../components/bootstrap/Popovers';
import Timeline, { TimelineItem } from '../../components/extras/Timeline';
import Modal, { ModalBody } from '../../components/bootstrap/Modal';
import RequestModule from '../../modules/sodiq/DocumentRequestModule';
import DocumentTypeModule from '../../modules/sodiq/DocumentTypeModule';
import DocumentPriorityModule from '../../modules/bakti/DocumentPriorityModule';
import { getRequester } from '../../helpers/helpers';

export const REJECT = [
	'Rejected Creator',
	'Rejected QAS',
	'Rejected Checker',
	'Rejected Acknowledge',
	'Final Rejected',
];
const APPROVE = ['Approval Creator', 'Approval Checker', 'Approval Acknowledge', 'Final Approval'];
const INIT = ['Submitted', 'Distributed'];

export const LIST_STATUS = [
	{ labelText: 'Step 1', label: 'Submitted', value: 'Submitted' },
	{ labelText: 'Step 2', label: 'Approval Creator', value: 'Approval Creator' },
	{ labelText: 'Step 3', label: 'Approval QAS', value: 'Approval QAS' },
	{ labelText: 'Step 4', label: 'Approval Checker', value: 'Approval Checker' },
	{ labelText: 'Step 5', label: 'Final Approval', value: 'Final Approval' },
	{ labelText: 'Step 6', label: 'Distributed', value: 'Distributed' },
];

export const errorMessage = {
	width: '100%',
	marginTop: '0.25rem',
	fontSize: '0.875em',
	color: '#f35421',
};

export const splitItem = (datas, type) => {
	let temp = '';
	if (datas && datas.length > 0) {
		datas.forEach((item) => {
			if (temp === '') {
				temp = `${
					// eslint-disable-next-line no-nested-ternary
					type === 'code'
						? item.material_number
						: type === 'dept_distribute'
						? item
						: item
				}`;
			} else {
				temp = `${temp}, ${
					// eslint-disable-next-line no-nested-ternary
					type === 'code'
						? item.material_number
						: type === 'dept_distribute'
						? item
						: item
				}`;
			}
		});
	}
	return temp;
};

export const format = {
	Values: (val) => {
		return val ?? '';
	},
	Status: (status) => {
		const { darkModeStatus } = useDarkMode();
		let color = '';
		let colorFont = '';
		if (INIT.includes(status)) {
			color = 'info';
		} else if (APPROVE.includes(status)) {
			color = 'success';
		} else if (status === 'Approval QAS') {
			color = 'primary';
		} else if (REJECT.includes(status)) {
			color = 'danger';
		} else {
			color = 'warning';
			colorFont = '#000';
		}

		if (color !== '') {
			return (
				<Badge
					isLight={darkModeStatus}
					rounded='pill'
					color={color}
					style={{ color: colorFont }}>
					{status}
				</Badge>
			);
		}
		return '';
	},
	DateComplate: (val) => {
		return moment(val).format('DD-MM-YYYY HH:mm');
	},
	Dates: (val) => {
		return moment(val).format('DD MMMM YYYY');
	},
	Times: (val) => {
		return moment(val).format('HH:mm');
	},
};

export const ParseValues = {
	User: (username, name) => {
		let response = '-';
		if (username && name) {
			response = `${decode(username)} - ${decode(name)}`;
		}
		if (username === 'no_approval') {
			response = 'NON APPROVAL';
		}
		return response;
	},
};

export const loadOptionsDocument = async (find) => {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve(fetchDataAsyncSelect(find, 'document'));
		}, 1000);
	});
};

export const loadOptionsCategory = async (find) => {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve(fetchDataAsyncSelect(find, 'category'));
		}, 1000);
	});
};

export const loadOptionsCategoryApproval = async (find) => {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve(fetchDataAsyncSelect(find, 'approval'));
		}, 1000);
	});
};

export const loadOptionsMaterial = async (find) => {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve(fetchDataAsyncSelect(find, 'material'));
		}, 1000);
	});
};

export const loadOptionsUser = async (find) => {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve(fetchDataAsyncSelect(find, 'user'));
		}, 1000);
	});
};

export const loadOptionsStatus = async (find) => {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve(fetchDataAsyncSelect(find, 'status'));
		}, 1000);
	});
};

export const loadOptionsDepartment = async (find) => {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve(fetchDataAsyncSelect(find, 'dept'));
		}, 1000);
	});
};

export const loadOptionsDepartmentNew = async (find) => {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve(fetchDataAsyncSelect(find, 'dept_new'));
		}, 1000);
	});
};

export const loadOptionsDocumentType = async (find) => {
	return new Promise((resolve) => {
		setTimeout(() => {
			fetchDataAsyncSelect(find, 'doc_type').then((res) => {
				resolve(res.foundData);
			});
		}, 1000);
	});
};

export const loadOptionsUserSpv = async (find) => {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve(fetchDataAsyncSelect(find, 'user_spv'));
		}, 1000);
	});
};

export const loadOptionsDocumentPriority = async (search) => {
	return new Promise((resolve) => {
		setTimeout(() => {
			const query = { search, perPage: 30 };
			DocumentPriorityModule.read(query)
				.then((res) => {
					const list = [...res.foundData].map((item) => ({
						...item,
						value: item.name,
						label: item.name,
					}));

					resolve(list);
				})
				.catch(() => {
					resolve([]);
				});
		});
	});
};

export const loadOptionsUserApproval = async (payload) => {
	return new Promise((resolve) => {
		setTimeout(() => {
			RequestModule.getApproval(new URLSearchParams(payload))
				.then((res) => {
					resolve(res);
				})
				.catch(() => {
					resolve([]);
				});
		});
	});
};

export const fetchDataAsyncSelect = async (find, type, isDefault = false) => {
	if (find?.length >= 3 || isDefault) {
		if (type === 'document') {
			const { department } = getRequester();
			const payload = {
				search: find,
				department_user: department,
			};
			const result = RequestModule.getDocument(new URLSearchParams(payload));
			if (result) {
				return result;
			}
		}
		if (type === 'category') {
			const payload = {
				search: find,
			};
			const result = RequestModule.getCategory(new URLSearchParams(payload));
			if (result) {
				return result;
			}
		}
		if (type === 'material') {
			const payload = {
				search: find,
			};
			const result = RequestModule.getMaterial(new URLSearchParams(payload));
			if (result) {
				return result;
			}
		}
		if (type === 'user') {
			const { department } = getRequester();
			const payload = {
				search: find,
				department,
			};
			const result = RequestModule.getUser(new URLSearchParams(payload));
			if (result) {
				return result;
			}
		}
		if (type === 'user_spv') {
			const payload = {
				search: find,
				position: 'Y',
			};
			const result = RequestModule.getUser(new URLSearchParams(payload));
			if (result) {
				return result;
			}
		}
		if (type === 'dept') {
			const payload = {
				search: find,
			};
			const result = RequestModule.getDepartment(new URLSearchParams(payload));
			if (result) {
				return result;
			}
		}
		if (type === 'dept_new') {
			const { department } = getRequester();
			const payload = {
				search: find,
				isSelect: true,
				department,
			};
			const result = RequestModule.deptByOrg(new URLSearchParams(payload));
			if (result) {
				return result;
			}
		}
		if (type === 'status') {
			const result = LIST_STATUS.filter((e) =>
				e.value.toLowerCase().includes(find.toLowerCase()),
			);
			return result;
		}
		if (type === 'doc_type') {
			const payload = {
				search: find,
			};
			const result = DocumentTypeModule.read(new URLSearchParams(payload));
			if (result) {
				return result;
			}
		}
		if (type === 'approval') {
			const payload = {
				search: find,
			};
			const result = RequestModule.getCategoryApproval(new URLSearchParams(payload));
			if (result) {
				return result;
			}
		}
		return [];
	}
	return [];
};

export const fetchDataAsyncSelectApproval = async (payload, isDefault = false) => {
	if (payload?.search?.length >= 3 || isDefault) {
		const result = RequestModule.getApproval(new URLSearchParams(payload));
		if (result) {
			return result;
		}
		return [];
	}
	return [];
};

export const StatusInformation = (dt) => {
	const { placement, data, title, color } = dt;
	return (
		<Popovers
			desc={
				<>
					<Icon
						icon='InfoCircle'
						size='2x'
						color='primary'
						style={{ marginTop: '-5px' }}
					/>{' '}
					<label style={{ fontSize: '15px' }}>{title}</label>
					<Timeline style={{ marginTop: '15px', maxHeight: '400px', overflowY: 'auto' }}>
						{data &&
							data.length > 0 &&
							data.map((item) => (
								<TimelineItem
									label={
										item.labelText || (
											<>
												<span>{format.Times(item.created_at)}</span>
												<br />
												<small className='small history'>{`(${format.Dates(
													item.created_at,
												)})`}</small>
											</>
										)
									}
									color={color || 'primary'}>
									{item.value || (
										<>
											<b>{item.status}</b>{' '}
											{(item?.status?.includes('Approval') ||
												item?.status?.includes('Rejected')) &&
												item?.is_approval_email && (
													<Popovers
														desc='Approval via Email'
														trigger='hover'>
														<Icon
															icon='MarkEmailRead'
															size='lg'
															color='primary'
														/>
													</Popovers>
												)}
											<br />
											<small className='small history'>{`(${item.username} - ${item.name})`}</small>
										</>
									)}
								</TimelineItem>
							))}
					</Timeline>
				</>
			}
			placement={placement}>
			<Icon icon='Info' size='lg' color='primary' style={{ cursor: 'pointer' }} />
		</Popovers>
	);
};

export const wajib = () => {
	return <span style={{ color: 'red' }}>*</span>;
};

export const remark = (listHistory, status) => {
	let res = '';
	if (listHistory && listHistory.length > 0 && REJECT.includes(status)) {
		const find = listHistory.find((e) => e.status === status);
		if (find) {
			res = find.remark;
		}
	}
	return res;
};

export const documentType = (document_type) => {
	let res = '';
	if (document_type) {
		if (typeof document_type === 'object') {
			if (document_type.length > 0) {
				res = document_type.join(', ');
			}
		}
		if (typeof document_type === 'string') {
			res = document_type;
		}
	}
	return res;
};

export const documentPriority = (data) => {
	if (!data) return '-';

	return <Badge color={data?.color}>{data?.name}</Badge>;
};

export const ModalLoading = (dt) => {
	const { loading, setLoading } = dt;
	return (
		<Modal isOpen={loading} size='sm' isCentered setIsOpen={setLoading} isStaticBackdrop>
			<ModalBody
				style={{ backgroundColor: '#6c5dd3', color: 'white' }}
				className='text-center'>
				<button className='btn btn-primary' type='button' disabled>
					<span
						className='spinner-grow spinner-grow-sm'
						role='status'
						aria-hidden='true'
					/>
					&nbsp;
					<span
						className='spinner-grow spinner-grow-sm'
						role='status'
						aria-hidden='true'
					/>
					&nbsp;
					<span
						className='spinner-grow spinner-grow-sm'
						role='status'
						aria-hidden='true'
					/>
					&nbsp;
					<span className='sr-only'>Loading...</span>
				</button>
			</ModalBody>
		</Modal>
	);
};
